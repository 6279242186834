export const hexToRgb = function  (hex) {
  let result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex)
  return result ? [
    parseInt(result[1], 16),
    parseInt(result[2], 16),
    parseInt(result[3], 16)
  ] : hex;
}

export const getCorrectTextColor = function (param){
  let threshold = 230; /* about half of 256. Lower threshold equals more dark text on dark background  */

  let color = hexToRgb(param)

  let cBrightness = ((color[0] * 299) + (color[1] * 587) + (color[2] * 114)) / 1000;
  if (cBrightness > threshold) {
    return '#64656A';
  } else {
    return '#ffffff';
  }
}
