import React, { Component } from 'react'
import Recaptcha from 'react-google-recaptcha'

const RECAPTCHA_KEY = "6Lc_mi0iAAAAAPxvjkOBguy-Y9VKzHpmRV6HIGSi"

function encode(data) {
  return Object.keys(data)
    .map(key => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
    .join("&")
}

class Newsletter extends Component {

	state = {
		result: ''
	}

  constructor(props) {
    super(props)
    this.form = React.createRef()
  }

	_handleChange = e => {
    this.setState({
      [`${e.target.name}`]: e.target.value,
    })
  }

  handleRecaptcha = value => {
    this.setState({ "g-recaptcha-response": value });
  }

  handleSubmit = async (e) => {
    e.preventDefault()

    if (!this.form.current.checkValidity()) return false

    const formData = new FormData(this.form.current)
    formData.append('form-name', this.form.current.getAttribute("name"))
    const queryString = new URLSearchParams(formData).toString()

    this.setState({msg: 'Thanks for subscribing!', result: 'success'})

    // https://zapier.com/editor/204110806/published/204110807/sample
    await fetch("https://hooks.zapier.com/hooks/catch/2181815/39zcnai/?"+queryString)
  }

  render() {

    let props = {
      ref: this.form,
      name: 'newsletter',
      onSubmit: this.handleSubmit,
    }

    return (
      <div className="signup__nav">
        <form {...props}>
          <p className="paragraph">Obvious: An email that has value. Like, the latest transactions and the newest listings. Stay up-to-date with all things WHITEFOX.</p>
          <div className='signup__double'>
            <input type='text' name='fname' placeholder='First Name' onChange={this._handleChange} />
            <input type='text' name='lname' placeholder='Last Name' onChange={this._handleChange} />
          </div>
          <input type='email' name='email' placeholder='Email' onChange={this._handleChange} />
          <div className='recaptcha__wrapper'>
            <Recaptcha
              ref='recaptcha'
              sitekey={RECAPTCHA_KEY}
              onChange={this.handleRecaptcha}
            />
          </div>
          <button className="contactbtn" type='submit'>Subscribe</button>
          { this.state.result !== '' &&
	          <p className="paragraph" dangerouslySetInnerHTML={{ __html: this.state.msg }} />
	        }
        </form>
      </div>
    )
  }
}

export default Newsletter
