import React, { Component } from 'react'

import { Logo, MiniLogo, VerticalLogo } from './logo'
import Newsletter from './newsletter'
import Social from './social'
import Load from '../assets/images/load.png'
import Link from '../utils/link'

import { getCorrectTextColor, hexToRgb } from '../utils/color'

class Header extends Component {

  state = {
    scrolled: false,
    menuActive: false,
    pageContext: this.props.pageContext || {},
    showHeader: this.props.location.pathname !== '/' && this.props.location.pathname !== '/advocacy/',
    aniEnd: this.props.location.pathname !== '/',
    lastScrollTop: 0,
    color: '#64656A', //'#ffffff',
    dropdown: false,
    property: false,
    management: false,
    about: false,
    menuHover: [],
  }

	componentDidMount() {
    window.addEventListener('scroll', this.scrollDetect)
    this.selectFocus()

    // Begin animation
    let wait = 6000
    this.timeout = setTimeout(() => {
      this.onScroll()
      this.setState({ showHeader: true, aniEnd: true })
    }, wait)
	}

	componentWillUnmount() {
	  window.removeEventListener('scroll', this.scrollDetect)
	}

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.location.pathname !== this.props.location.pathname) {
      // Trigger selectFocus after transition has completed
      setTimeout(() => this.selectFocus(), 10)
    }
  }

  _toggleOffCanvas = () => {
    this.setState({ menuActive: !this.state.menuActive })
    document.querySelector('body').classList.toggle('menu-open')
  }

  _hideOffCanvas = () => {
    this.setState({ menuActive: false, flyoutActive: false, menuHover: [] })
    document.querySelector('body').classList.remove('menu-open', 'flyout-open', 'active')
    setTimeout(() => {
      let homeBanner = document.querySelector('.home-banner')
      if (homeBanner) homeBanner.classList.add('home-banner--loaded')
    }, 100)
  }

  _toggleNewsletter = () => {
    this.setState({ signupActive: !this.state.signupActive })
    document.querySelector('body').classList.toggle('active')
  }

  _hideNewsletter = () => {
    this.setState({ signupActive: false })
  }

  onScroll = () => {
    this.selectFocus()

    const dist = 82 // distance from top to start hiding
    let st = window.pageYOffset || window.scrollY

    let hb = document.querySelector('.home-banner')
    let bb = hb && hb.style.display !== 'none'

    this.setState({
      scrolled: window.scrollY > window.innerHeight,
      lastScrollTop: st <= dist ? dist : st,
      showHeader: window.innerWidth > 900 || bb || st <= this.state.lastScrollTop,
      aniEnd: true,
    })
  }

	scrollDetect = () => {
    window.clearTimeout( this.isScrolling )
    this.isScrolling = setTimeout(this.onScroll, 0);
	}

  selectFocus = () => {
    const middle = Math.max(document.documentElement.clientHeight, window.innerHeight) / 2
    const elms = []
    document.querySelectorAll('main > section').forEach((el, i) => {
      let box = el.getBoundingClientRect()
      elms.push({ pos: box.top + (box.height / 2), elem: el })
    })
    let index = 0

    elms.forEach((el, i) => {
      if (Math.abs(middle - el.pos) < Math.abs(middle - elms[index].pos)){
        index = i
      }
    })

    if (!elms[index]) return
    let data = elms[index].elem.dataset
    let hex = data.color || '#ffffff'
    // If format is incorrect
    if (hex.length === 4 && hex.charAt(0) === '#') {
      hex = `#${hex.charAt(1)}${hex.charAt(1)}${hex.charAt(2)}${hex.charAt(2)}${hex.charAt(3)}${hex.charAt(3)}`
    }
    let bgc = hex.toLowerCase()
    let cbgc = getCorrectTextColor(bgc)
    let $main = document.querySelector('main')
    $main.style.backgroundColor = bgc
    $main.style.color = cbgc
    document.documentElement.style.setProperty('--theme-background-color', bgc)
    document.documentElement.style.setProperty('--theme-text-color', cbgc)
    this.setState({ color: cbgc })
  }

  render() {
    let { menuActive, showHeader, color, signupActive, scrolled, property, menuHover } = this.state
    const { menu, mobileMenu } = this.props.data.wp.siteSettings.acf

    let props = {
      onClick: this._hideOffCanvas
    }

    let apartments = this.props.location.pathname.indexOf('/classics') !== -1

    let headerClass = "header"
    if (!showHeader) headerClass += " header--hide"
    if (scrolled) headerClass += " header--scrolled"

    return (
      <>
        <header className={headerClass} tabIndex="0">
          <div className='header__inner'>
            <button onClick={this._toggleOffCanvas}>
              <MiniLogo color={!menuActive ? color : '#64656A'} />
            </button>
            <Link to='/' onClick={this._hideOffCanvas}><Logo color={!menuActive ? color : '#64656A'} apartments={apartments} /></Link>
            <button className={ this.state.menuActive ? 'hamburger active' : 'hamburger' } onClick={this._toggleOffCanvas}>
              <span className='lines'></span>
            </button>
            { process.env.GATSBY_LOCATION !== 'gc' &&
              <nav>
                <ul>
                  { menu?.map((item, i) => (
                    <li 
                      onMouseEnter={() => { menuHover[i] = true; this.setState({ menuHover }) }}
                      onMouseLeave={() => { menuHover[i] = false; this.setState({ menuHover }) }}
                      className={` ${menuHover[i] ? 'hover' : ''}`}
                      key={i}
                    >
                      <Link 
                        to={item.link.url} 
                        onClick={() => { menuHover[i] = true; this.setState({ menuHover }) }}
                      >{item.link.title}</Link>
                      { item.dropdown?.length > 0 && (
                        <div className='header__dropdown'>
                          <div className='header__inner'>
                            <ul>
                              { item.dropdown && item.dropdown.map((dropdown, d) => {
                                if (dropdown.list?.title === '') return <li key={d}>&nbsp;</li>
                                return (
                                  <li key={d}>
                                    <ul>
                                      { dropdown.list && dropdown.list.map((list, l) => (
                                        <li key={l}><Link to={list.link?.url} {...props}>{list.link?.title}</Link></li>
                                      ))}
                                    </ul>
                                  </li>
                                )
                              })}
                              <li></li>
                            </ul>
                          </div>
                        </div>
                      )}
                    </li>
                  ))}
                </ul>
              </nav>
            }
          </div>
          <div className='header__advocacy'>
            <Link className='btn' to='#contact'>
              Contact Us
            </Link>
            { (process.env.GATSBY_LOCATION === 'adelaide' || process.env.GATSBY_LOCATION === 'brisbane') &&
              <Link className='btn' to={process.env.GATSBY_LOCATION === 'adelaide' ? 'mailto:adelaide@whitefoxrealestate.com.au' : 'mailto:brisbane@whitefoxrealestate.com.au'}>
                Email
              </Link>
            }
            { process.env.GATSBY_LOCATION !== 'adelaide' && process.env.GATSBY_LOCATION !== 'brisbane' && process.env.GATSBY_LOCATION !== 'gc' &&
              <Link className='btn' to={process.env.GATSBY_LOCATION === 'perth' ? 'mailto:perth@whitefoxrealestate.com.au' : 'mailto:martin@whitefoxadvocacy.com.au' }>
                Email
              </Link>
            }
            { process.env.GATSBY_LOCATION === 'gc' &&
              <Link className='btn' to={'mailto:marty@whitefoxadvocacy.com.au' }>
                Email
              </Link>
            }
          </div>
        </header>

        <div className={`header__lhs ${showHeader ? '' : 'header__lhs--hide'}`}>
          <Link to='/' {...props}><VerticalLogo color={!menuActive ? color : '#64656A'} /></Link>
          <Social />
          <button>
            <MiniLogo color={!menuActive ? color : '#64656A'} />
          </button>
        </div>
        <div className={`header__rhs ${showHeader ? '' : 'header__rhs--hide'}`}>
          <ul>
            <li><Link className={process.env.GATSBY_LOCATION === 'aus' ? 'active' : ''} to='https://whitefoxrealestate.com.au'>MEL</Link></li>
            <li><Link className={process.env.GATSBY_LOCATION === 'perth' ? 'active' : ''} to='https://perth.whitefoxrealestate.com.au'>PER</Link></li>
            <li><Link className={process.env.GATSBY_LOCATION === 'adelaide' ? 'active' : ''} to='https://adelaide.whitefoxrealestate.com.au'>ADL</Link></li>
            <li><Link className={process.env.GATSBY_LOCATION === 'brisbane' ? 'active' : ''} to='https://brisbane.whitefoxrealestate.com.au'>BRI</Link></li>
            <li><Link className={process.env.GATSBY_LOCATION === 'gc' ? 'active' : ''} to='https://goldcoast.whitefoxrealestate.com.au'>GC</Link></li>
            <li><Link className={process.env.GATSBY_LOCATION === 'nz' ? 'active' : ''} to='https://whitefoxrealestate.co.nz'>NZ</Link></li>
          </ul>
        </div>

        <div className={`${menuActive ? 'off-canvas active' : 'off-canvas'}`}>
          <nav className='off-canvas__nav'>
            <ul>
              { process.env.GATSBY_LOCATION !== 'gc' && mobileMenu?.map((item, i) => {
                if ( item.dropdown?.length > 0 ) return (
                  <li key={i}>
                    <button type='button' onClick={() => this.setState({ property: !property })}>{item.link.title}</button>
                    <ul className={property ? 'active' : ''}>
                      { item.dropdown && item.dropdown.map((list, l) => (
                        <li key={l}><Link to={list.link?.url} {...props}>{list.link?.title}</Link></li>
                      ))}
                    </ul>
                  </li>
                )
                return (
                  <li key={i}>
                    <Link to={item.link.url} {...props}>{item.link.title}</Link>
                  </li>
                )
              })}
              <li onClick={this._toggleNewsletter} className='newsletter-click'><span>Subscribe</span></li>
            </ul>
          </nav>
          <ul className='off-canvas__country'>
            <li><Link className={process.env.GATSBY_LOCATION === 'aus' ? 'active' : ''} to='https://whitefoxrealestate.com.au'>MEL</Link></li>
            <li><Link className={process.env.GATSBY_LOCATION === 'perth' ? 'active' : ''} to='https://perth.whitefoxrealestate.com.au'>PER</Link></li>
            <li><Link className={process.env.GATSBY_LOCATION === 'adelaide' ? 'active' : ''} to='https://adelaide.whitefoxrealestate.com.au'>ADL</Link></li>
            <li><Link className={process.env.GATSBY_LOCATION === 'brisbane' ? 'active' : ''} to='https://brisbane.whitefoxrealestate.com.au'>BRI</Link></li>
            <li><Link className={process.env.GATSBY_LOCATION === 'gc' ? 'active' : ''} to='https://goldcoast.whitefoxrealestate.com.au'>GC</Link></li>
            <li><Link className={process.env.GATSBY_LOCATION === 'nz' ? 'active' : ''} to='https://whitefoxrealestate.co.nz'>NZ</Link></li>
          </ul>
          <Social />
        </div>

        <div className='header__padding' />

        <div className={`signup ${signupActive ? 'active' : ''}`}>
          <Newsletter />
          <div className="signup__close">
            <button className={ signupActive ? 'active' : '' } onClick={this._hideNewsletter}>
              <img src={Load} alt="Close sign up form"/>
            </button>
          </div>
        </div>
      </>
    )
  }
}

export default Header
