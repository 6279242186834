import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { useStaticQuery, graphql } from 'gatsby'

import Header from '../components/header'
import Footer from '../components/footer'

import '../assets/styles/main.scss'

const useLayoutQuery = () => {
  const data = useStaticQuery(
    graphql`
        fragment WpSeo on WpPostTypeSEO {
          metaDesc
          metaKeywords
          metaRobotsNofollow
          opengraphAuthor
          opengraphDescription
          opengraphImage {
            localFile {
              publicURL
            }
          }
          opengraphModifiedTime
          opengraphPublishedTime
          opengraphPublisher
          opengraphSiteName
          opengraphTitle
          opengraphType
          opengraphUrl
          readingTime
          schema {
            articleType
            pageType
            raw
          }
          title
          twitterDescription
          twitterImage {
            localFile {
              publicURL
            }
          }
          twitterTitle
        }
        fragment PropertyCard on WpProperty {
          databaseId
          id
          slug
          title
          date
          propertyData {
            office_id
            consultant_ids
            status
            listing_type
            hidden
            price_undisclosed
            address_undisclosed
            suburb_undisclosed
            under_offer
            website_status
            auction
            auction_date
            price_from
            price_to
            display_price
            date_listed
            campaign_start_date
            record_price
            sale_date
            sale_price
            sale_status
          }
          propertyImages {
            index
            url
            medium
            large
          }
          propertyMeta {
            unit
            number
            street_name
            street_type
            suburb
            postcode
            state
            country
            latitude
            longitude
            property_type_id
            property_category_id
            property_styles
            beds
            baths
            garages
            cars
            open_spaces
          }
        }
        query SiteTitleQuery {
          site {
            siteMetadata {
              title
            }
          }
          wp {
            siteSettings {
              acf {
                menu {
                  link {
                    title
                    url
                  }
                  dropdown {
                    list {
                      link {
                        title
                        url
                      }
                    }
                  }
                }
                mobileMenu {
                  link {
                    title
                    url
                  }
                  dropdown {
                    link {
                      title
                      url
                    }
                  }
                }
                disclaimer
              }
            }
          }
        }
      `
  )
  return data
}

const Layout = ({ children, location, pageContext }) => {

  const data = useLayoutQuery()

  const [pathName, setPathName] = useState("")

  useEffect(() => {
    setPathName(window.location.pathname)
  })

  return (
    <>
      {pageContext?.layout !== 'basic' && <Header location={location} data={data} />}
      <main>{children}</main>
      {pageContext?.layout !== 'basic' && <Footer page={pathName === "/advocacy" || pathName === "/advocacy/" ? "WFLanding" : ""} data={data} />}
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
